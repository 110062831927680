import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_SITE } from "src/constants/constants";

type ChatTextData = {
  content: string;
  reference: string;
};

type ChatResponse = {
  question_id: string;
  question_text: string;
  successful: string;
  llm_response: string;
  chartResult: string;
  thumbsUpClicked: boolean;
  thumbsDownClicked: boolean;
  voteResponse: string;
  isTextOnly: boolean;
  textData: ChatTextData;
  question_recap: string;
  isEmptyAnswer: boolean;
  isEmptyChart: boolean;
};

export type ChatQuestion = {
  question_text: string;
  question_id: string;
  timestamp: string;
};

export type ChatState = {
  inputText: string;
  recentInput: string;
  isLoading: boolean;
  isInputDisabled: boolean;
  showResult: boolean;
  errorMessage: string;
  responses: ChatResponse[];
  session_id: string;
  isRecentChatsLoaded: boolean;
  recentChats: ChatQuestion[];
  site: string;
};

const initialState: ChatState = {
  inputText: "",
  recentInput: "",
  isLoading: false,
  isInputDisabled: false,
  showResult: false,
  errorMessage: "",
  responses: [],
  session_id: "",
  isRecentChatsLoaded: false,
  recentChats: [],
  site: DEFAULT_SITE,
};

/** Chat Slice */
const { reducer, actions } = createSlice({
  name: "chatSlice",
  initialState,
  reducers: {
    setInputText: (state, action) => {
      state.inputText = action.payload;
    },
    setRecentInput: (state, action) => {
      state.recentInput = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsInputDisabled: (state, action) => {
      state.isInputDisabled = action.payload;
    },
    setShowResult: (state, action) => {
      state.showResult = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setResponses: (state, action) => {
      action.payload === null
        ? (state.responses = [])
        : (state.responses = [...state.responses, action.payload]);
    },
    updateResponses: (state, action) => {
      const { question_id, updates } = action.payload;
      const existingResponse = state.responses.find(
        (response) => response.question_id === question_id,
      );
      if (existingResponse) {
        Object.assign(existingResponse, updates);
      }
    },
    setSessionId: (state, action) => {
      state.session_id = action.payload;
    },
    setIsRecentChatsLoaded: (state, action) => {
      state.isRecentChatsLoaded = action.payload;
    },
    setRecentChats: (state, action) => {
      state.recentChats = action.payload;
    },
    setSite: (state, action) => {
      state.site = action.payload;
    },
  },
});

export const {
  setInputText,
  setRecentInput,
  setIsLoading,
  setIsInputDisabled,
  setShowResult,
  setErrorMessage,
  setResponses,
  updateResponses,
  setSessionId,
  setIsRecentChatsLoaded,
  setRecentChats,
  setSite,
} = actions;

export default reducer;

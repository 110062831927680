import {
  createSlice,
  createAsyncThunk,
  miniSerializeError,
  SerializedError,
} from "@reduxjs/toolkit";
import { LOADING_STATE } from "../constants/constants";
import authService from "src/services/authService";

const AUTHORIZED_LDAP_GROUPS: string[] = [
  "whs-safer-user",
  "workingwell-engineer",
];

export type UserState = {
  token: string;
  alias: string;
  lastName: string;
  firstName: string;
  email: string;
  ldap: string[];
  loc_descr: string;
  userLoading: LOADING_STATE;
  authError: SerializedError | null;
  isAuthorized: boolean;
};

const initialState: UserState = {
  token: "",
  alias: "",
  lastName: "",
  firstName: "",
  email: "",
  ldap: [],
  loc_descr: "",
  userLoading: "pending",
  authError: null,
  isAuthorized: false,
};

/**
 * get the authenticated user
 * @returns {Object}
 */
export const getUser = createAsyncThunk("user/getUser", async () => {
  return authService.login();
});

/** User Slice */
const { reducer, actions } = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserFailure: (state) => {
      state.userLoading = "rejected";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.userLoading = "pending";
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      const { alias, token, email, firstName, lastName, ldap, loc_descr } =
        payload;
      state.token = token;
      state.alias = alias;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.ldap = ldap;
      state.userLoading = "fulfilled";
      state.loc_descr = loc_descr;
      state.isAuthorized = AUTHORIZED_LDAP_GROUPS.some((authorizedGroup) =>
        state.ldap?.includes(authorizedGroup),
      );
    });
    builder.addCase(getUser.rejected, (state, { error }) => {
      state.authError = miniSerializeError(error);
      state.userLoading = "rejected";
      state.isAuthorized = false;
    });
  },
});

export default reducer;

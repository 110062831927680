import React from "react";
import Chat from "src/components/chat/chat";
import Text from "@amzn/meridian/text";
import { useAppSelector } from "src/store/store";
import "./mainContent.scss";
import Thumbnail from "@amzn/meridian/thumbnail";
import { BADGE_PHOTO_THUMBNAIL_URL } from "src/config/external-links";

const MainContent = () => {
  const { firstName, alias, isAuthorized, token } = useAppSelector(
    (state) => state.user,
  );
  const { showResult } = useAppSelector((state) => state.chatSlice);

  return (
    <div className="main">
      {!token ? (
        // User is not authenticated
        <div className="unauthorized">
          <Text type="b500">
            Welcome to Safer. Please hang on while we are authenticating you...
          </Text>
        </div>
      ) : !isAuthorized ? (
        // User is authenticated but not authorized
        <div className="unauthorized">
          <Text type="b500">
            Access is currently limited due to allowed/restricted group
            restrictions set by the safer owner. Please contact the service
            owner via SIM ticketing (CTI: WorkingWell / WorkingWell Program /
            Engineering).
          </Text>
        </div>
      ) : (
        // User is both authenticated and authorized
        <div>
          <div className="nav">
            <Text className="nav-info">Safer Chatbot</Text>
            <Thumbnail
              size="small"
              source={`${BADGE_PHOTO_THUMBNAIL_URL}${alias}`}
            ></Thumbnail>
          </div>
          <div className="main-container">
            {!showResult && (
              <div className="greet">
                <Text className="greeting">
                  <span>Hello, {firstName}</span>
                </Text>
                <Text className="greeting-info">
                  How can I help you today?{" "}
                </Text>
              </div>
            )}
            <Chat />
          </div>
        </div>
      )}
    </div>
  );
};

export default MainContent;

import React, { useEffect, useRef } from "react";
import "./chat.scss";
import Loader from "@amzn/meridian/loader";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { createToast } from "src/store/toastsSlice";
import { DEFAULT_SITE, TOAST_TIMEOUT } from "src/constants/constants";
import Thumbnail from "@amzn/meridian/thumbnail";
import { BADGE_PHOTO_THUMBNAIL_URL } from "src/config/external-links";
import sendIcon from "src/images/send_icon.png";
import Textarea from "@amzn/meridian/textarea";
import Text from "@amzn/meridian/text";
import {
  setErrorMessage,
  setInputText,
  setIsRecentChatsLoaded,
  setRecentChats,
  setSite,
} from "src/store/chatSlice";
import ChatHandleSubmit from "src/components/chat/chatHandleSubmit";
import Button from "@amzn/meridian/button";
import ChatService from "src/services/chatService";
import ChatResult from "src/components/chat/content/chatResult";
import ParseService from "src/services/parseService";
import ChatCards from "src/components/chat/content/chatCards";

const Chat = () => {
  const dispatch = useAppDispatch();
  const inputRef = useRef(null);

  const {
    inputText,
    isLoading,
    isInputDisabled,
    showResult,
    errorMessage,
    responses,
  } = useAppSelector((state) => state.chatSlice);
  const { alias, token, loc_descr, isAuthorized } = useAppSelector(
    (state) => state.user,
  );

  useEffect(() => {
    const fetchSite = async () => {
      if (isAuthorized && loc_descr !== "") {
        try {
          const resultSite = await ParseService.getSite(loc_descr);
          dispatch(setSite(resultSite));
        } catch (error) {
          dispatch(setSite(DEFAULT_SITE));
        }
      }
    };

    fetchSite();
  }, [token]);

  useEffect(() => {
    if (isAuthorized && alias !== "") {
      const fetchChatHistory = async () => {
        dispatch(setIsRecentChatsLoaded(false));
        try {
          const response = await ChatService.getRecentChats();

          dispatch(setRecentChats(response.data.questions));
          dispatch(setIsRecentChatsLoaded(true));
        } catch (err) {
          dispatch(setErrorMessage("Failed to fetch chat history"));
        }
      };

      fetchChatHistory();
    }
  }, [alias]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    setTimeout(function () {
      inputRef.current.focus();
      inputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  }, [responses]);

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        createToast({
          type: "error",
          message: errorMessage,
          timeout: TOAST_TIMEOUT,
        }),
      );
      inputRef.current.focus();
    }
  }, [errorMessage]);

  const inputTextArea = document.getElementById("chat-input-text-area");
  if (inputTextArea) {
    inputTextArea.addEventListener("keydown", function (e) {
      if (e.keyCode === 13) {
        document.getElementById("chat-input-button").click();
        e.stopImmediatePropagation();
        e.stopPropagation();
      }
    });
  }

  return (
    <div className="main-chat">
      {!showResult ? (
        <ChatCards inputRef={inputRef} />
      ) : (
        <div className="result">
          {responses.length !== 0 &&
            responses.map((output) => (
              <div
                key={output.question_id}
                className="results"
                id={output.question_id}
              >
                <div className="result-title">
                  <Thumbnail
                    size="small"
                    source={`${BADGE_PHOTO_THUMBNAIL_URL}${alias}`}
                  ></Thumbnail>
                  <Text type="b300">{output.question_text}</Text>
                </div>
                <ChatResult output={output} />
              </div>
            ))}
        </div>
      )}

      <div className="chat-input-form">
        <div className="chat-search-input">
          <Textarea
            id="chat-input-text-area"
            ref={inputRef}
            value={inputText}
            onChange={(inputText) => dispatch(setInputText(inputText))}
            placeholder="Enter a prompt here"
            resize="auto"
            rows={1}
            disabled={isInputDisabled}
          />
          <Button
            id="chat-input-button"
            type="icon"
            onClick={ChatHandleSubmit(inputText)}
            disabled={inputText === ""}
          >
            <img
              src={sendIcon}
              alt="Safer Search Send Icon"
              className="chat-search-send-icon"
            />
          </Button>
        </div>
        {isLoading && (
          <div className="loader">
            <Loader type="circular" size="medium" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;

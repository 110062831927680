import React, { useState } from "react";
import "./sideMenu.scss";
import SideMenu, {
  SideMenuLink,
  SideMenuTitle,
} from "@amzn/meridian/side-menu";
import Row from "@amzn/meridian/row";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Thumbnail from "@amzn/meridian/thumbnail";
import { BADGE_PHOTO_THUMBNAIL_URL } from "src/config/external-links";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Text from "@amzn/meridian/text";
import {
  setInputText,
  setIsInputDisabled,
  setIsLoading,
  setResponses,
  setSessionId,
  setShowResult,
} from "src/store/chatSlice";
import { v4 as uuidv4 } from "uuid";
import { sendCustomRUMEvent } from "src/utils/awsRUM";
import {
  ALIAS,
  HISTORY_QUESTION_CLICK,
  NEW_CHAT_CLICK,
  SESSION_ID,
} from "src/constants/constants";

const ChatSideMenu = () => {
  const dispatch = useAppDispatch();
  const { recentChats, isRecentChatsLoaded, session_id } = useAppSelector(
    (state) => state.chatSlice,
  );
  const { alias, firstName, isAuthorized } = useAppSelector(
    (state) => state.user,
  );
  const [openLinkHash, setOpenLinkHash] = useState(null);
  const [expandedSession, setExpandedSession] = useState(null);
  const [hash, setHash] = useState("#newChat");
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  const newChat = () => {
    dispatch(setShowResult(false));
    dispatch(setResponses(null));
    dispatch(setIsInputDisabled(false));
    dispatch(setIsLoading(false));
    dispatch(setInputText(""));
    dispatch(setSessionId(uuidv4()));

    sendCustomRUMEvent(NEW_CHAT_CLICK, {
      [ALIAS]: alias,
    });
  };

  const clickHistoryQuestion = (question_text) => {
    dispatch(setShowResult(false));
    dispatch(setResponses(null));
    dispatch(setIsInputDisabled(false));
    dispatch(setIsLoading(false));
    dispatch(setInputText(question_text));

    sendCustomRUMEvent(HISTORY_QUESTION_CLICK, {
      [ALIAS]: alias,
      [SESSION_ID]: setSessionId,
    });
  };

  return (
    <Row alignmentVertical="stretch" spacing="none">
      <SideMenu open={open} onClose={onClose} onOpen={onOpen} type="skinny">
        <SideMenuTitle>
          <Column>
            <Row alignmentHorizontal="center">
              <Thumbnail
                source={`${BADGE_PHOTO_THUMBNAIL_URL}${alias}`}
              ></Thumbnail>
            </Row>
            <Heading level={5} alignment="center">
              Hello, {firstName}
            </Heading>
          </Column>
        </SideMenuTitle>
        <SideMenuLink
          href="#newChat"
          selected={hash === "#newChat"}
          onClick={(hash) => {
            setHash(hash);
            newChat();
          }}
        >
          New Chat
        </SideMenuLink>
        <SideMenuLink
          href="#recentChats"
          selected={hash === "#recentChats"}
          onClick={(hash) => {
            setHash(hash);
            setOpenLinkHash(openLinkHash === "#recentChats" ? null : hash);
            setExpandedSession(
              expandedSession === "#recentChats" ? null : "#recentChats",
            );
          }}
        >
          Recent chats
          {isAuthorized && isRecentChatsLoaded ? (
            <div>
              {recentChats.length === 0 ? (
                <p>No recent chats available</p>
              ) : (
                <div>
                  {expandedSession !== "#recentChats" && (
                    <div>
                      {recentChats.map((chat) => (
                        <SideMenuLink
                          key={chat.question_id}
                          href={`#${chat.question_id}`}
                          selected={hash === `#${chat.question_id}`}
                          onClick={(hash) => {
                            setHash(hash);
                            setOpenLinkHash(
                              openLinkHash === chat.question_id
                                ? null
                                : chat.question_id,
                            );
                            clickHistoryQuestion(chat.question_text);
                          }}
                          onEscape={() => {
                            setHash("#recentChats");
                            setOpenLinkHash(null);
                          }}
                        >
                          <Text
                            type="b200"
                            id="side-menu-recent-chats-truncate"
                          >
                            {chat.question_text}
                          </Text>
                          <Text
                            type="b100"
                            id="side-menu-recent-chats-timestamp"
                          >
                            {formatTimestamp(chat.timestamp)}
                          </Text>
                        </SideMenuLink>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <Text type="b100">Loading recent chats...</Text>
          )}
        </SideMenuLink>
      </SideMenu>
    </Row>
  );
};

export default ChatSideMenu;

import Auth from "@aws-amplify/auth";

export async function Init(): Promise<void> {
  const config = {
    stage: "prod",
    aws_cognito_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:bacad770-958e-4fde-8efc-9559848a4201",
    aws_user_pools_id: "us-east-1_eYrPLcCiV",
    aws_user_pools_web_client_id: "19ngjr2gomjdub3cjue6bhpcj5",
    Auth: {
      identityPoolId: "us-east-1:bacad770-958e-4fde-8efc-9559848a4201",
      region: "us-east-1",
      userPoolId: "us-east-1_eYrPLcCiV",
      userPoolWebClientId: "19ngjr2gomjdub3cjue6bhpcj5",
    },
    oauth: {
      domain: `whs-safer-prod-na.auth.us-east-1.amazoncognito.com`,
      redirectSignIn: "https://prod.na.safer.whs.amazon.dev",
      redirectSignOut: "https://prod.na.safer.whs.amazon.dev",
      scope: ["openid", "profile"],
      responseType: "code",
    },
  };
  Auth.configure(config);
}

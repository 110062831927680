import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import userSlice from "src/store/userSlice";
import toastsSlice from "src/store/toastsSlice";
import errorBoundarySlice from "src/store/errorBoundarySlice";
import chatSlice from "src/store/chatSlice";

export const reducers = {
  user: userSlice,
  toasts: toastsSlice,
  errorBoundary: errorBoundarySlice,
  chatSlice: chatSlice,
};

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // to allow non serializable objects to be stored in redux (eg: Map, Set, classes etc.)
    }),
});
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

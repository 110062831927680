// @ts-ignore
import siteFile from "src/components/files/site.csv";
import Papa from "papaparse";
import {DEFAULT_SITE} from "src/constants/constants";
class ParseService {
    /**
     * Function to parse 'remote' CSV file.
     * @param file The file to parse
     * @param download download set to true indicates that the string you passed as the first
     * argument to parse() is actually a URL from which to download a file and parse its contents
     * @returns {Promise<Array>} An array of data record array which represents a line of data.
     */
    parseCSVFile = async (file) =>
        new Promise((resolve) => {
            Papa.parse(file, {
                download: true,
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                    resolve(result.data.map(row => row.site).filter(site => site));
                },
            });
        });

    getSite = async (site) => {
        const siteRecords = await this.parseCSVFile(siteFile);

        if (siteRecords.includes(site)) {
            return site;
        } else {
            return DEFAULT_SITE;
        }
    };
}

export default new ParseService();
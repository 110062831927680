import apiClient from "src/utils/apiClient";

export interface PostChartPayload {
  query: string;
  session_id: string;
}

export interface PutVotePayload {
  question_id: string;
  vote: string;
}

class ChatService {
  async postChart(payload: PostChartPayload) {
    return await apiClient.post("/chart/search", payload);
  }

  async putVote(payload: PutVotePayload) {
    return await apiClient.post("/chart/vote", payload);
  }

  async getRecentChats() {
    return await apiClient.get("/chart/recent/chats");
  }
}

export default new ChatService();

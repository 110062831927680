import React from "react";
import ChatService from "src/services/chatService";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  setInputText,
  setRecentInput,
  setIsLoading,
  setIsInputDisabled,
  setShowResult,
  setResponses,
  setErrorMessage,
  setRecentChats,
  setIsRecentChatsLoaded,
} from "src/store/chatSlice";
import {
  questionRecapForBreakdownTool,
  questionRecapForInjuryRateTool,
} from "src/utils/questionRecapUtils";
import {
  ALIAS,
  BREAKDOWN_QUESTION_COUNT,
  BREAKDOWN_TOOL,
  EMPTY_ANSWER_COUNT,
  EMPTY_ANSWER_LLM_CANNOT_ANSWER,
  EMPTY_ANSWER_QUESTION_TEXT,
  EMPTY_REASON,
  FAILED_QUESTION_COUNT,
  INJURY_RATE_QUESTION_COUNT,
  INJURY_RATE_TOOL,
  POLICY_QA_QUESTION_COUNT,
  POLICY_QA_QUESTION_SAIGE_MESSAGE,
  POLICY_QA_TOOL,
  QUESTION_TEXT,
  SESSION_ID,
} from "src/constants/constants";
import { sendCustomRUMEvent } from "src/utils/awsRUM";

const ChatHandleSubmit = (prompt) => {
  const dispatch = useAppDispatch();
  const { alias } = useAppSelector((state) => state.user);
  const { session_id } = useAppSelector((state) => state.chatSlice);

  return async () => {
    dispatch(setShowResult(true));
    dispatch(setIsInputDisabled(true));
    dispatch(setIsLoading(true));
    dispatch(setRecentInput(prompt));

    try {
      dispatch(setErrorMessage(""));

      if (prompt === "") {
        dispatch(setErrorMessage("Please enter the query content"));
        return;
      }

      const response = await ChatService.postChart({
        query: prompt,
        session_id: session_id,
      });

      const data = response.data;

      if (data.successful && data.isTextOnly) {
        sendCustomRUMEvent(POLICY_QA_QUESTION_SAIGE_MESSAGE, {
          [ALIAS]: alias,
          [EMPTY_ANSWER_QUESTION_TEXT]: prompt,
          [SESSION_ID]: session_id,
        });
      }

      let responseData = null;
      // Knowledge base returns content with "Sorry, I am unable to assist you with this request." for the
      // questions cannot provide answer without reference, set these answers emptyResponse message
      const emptyTextAnswer =
        data.successful === true &&
        data.text_data != null &&
        data.text_data.reference === "";

      const emptyChart =
        data.chart_json !== null &&
        data.chart_json !== undefined &&
        data.chart_json.data.length === 0;

      if (
        data.successful === false ||
        (data.successful === true &&
          data.chart_json == null &&
          data.text_data == null) ||
        emptyTextAnswer ||
        emptyChart
      ) {
        responseData = {
          question_id: data.question_id,
          question_text: prompt,
          successful: data.successful,
          llm_response: data.llm_response_json,
          chartResult: JSON.stringify(data.chart_json),
          thumbsUpClicked: false,
          thumbsDownClicked: false,
          voteResponse: "",
          isTextOnly: data.isTextOnly,
          textData: data.text_data,
          question_recap: "",
          isEmptyAnswer: true,
          isEmptyChart: emptyChart,
        };

        let emptyAnswerEventName = EMPTY_ANSWER_LLM_CANNOT_ANSWER;

        if (emptyTextAnswer) {
          emptyAnswerEventName = POLICY_QA_TOOL;
          sendCustomRUMEvent(POLICY_QA_QUESTION_COUNT, {
            [ALIAS]: alias,
            [EMPTY_ANSWER_QUESTION_TEXT]: prompt,
            [SESSION_ID]: session_id,
          });
        }

        if (emptyChart) {
          emptyAnswerEventName = data.llm_response_json.tool_name;

          const chartCountName =
            BREAKDOWN_TOOL === data.llm_response_json.tool_name
              ? BREAKDOWN_QUESTION_COUNT
              : INJURY_RATE_QUESTION_COUNT;
          sendCustomRUMEvent(chartCountName, {
            [ALIAS]: alias,
            [EMPTY_ANSWER_QUESTION_TEXT]: prompt,
            [SESSION_ID]: session_id,
          });
        }

        sendCustomRUMEvent(EMPTY_ANSWER_COUNT, {
          [ALIAS]: alias,
          [QUESTION_TEXT]: prompt,
          [EMPTY_REASON]: emptyAnswerEventName,
        });
      } else {
        const llm = data.llm_response_json;
        let questionRecap = "";
        if (INJURY_RATE_TOOL == llm.tool_name) {
          questionRecap = questionRecapForInjuryRateTool(llm);

          sendCustomRUMEvent(INJURY_RATE_QUESTION_COUNT, {
            [ALIAS]: alias,
            [QUESTION_TEXT]: prompt,
            [SESSION_ID]: session_id,
          });
        }

        if (BREAKDOWN_TOOL == llm.tool_name) {
          questionRecap = questionRecapForBreakdownTool(llm, llm.x.label);

          sendCustomRUMEvent(BREAKDOWN_QUESTION_COUNT, {
            [ALIAS]: alias,
            [QUESTION_TEXT]: prompt,
            [SESSION_ID]: session_id,
          });
        }

        if (data.isTextOnly) {
          sendCustomRUMEvent(POLICY_QA_QUESTION_COUNT, {
            [ALIAS]: alias,
            [QUESTION_TEXT]: prompt,
            [SESSION_ID]: session_id,
          });
        }

        responseData = {
          question_id: data.question_id,
          question_text: prompt,
          successful: data.successful,
          llm_response: data.llm_response_json,
          chartResult: JSON.stringify(data.chart_json),
          thumbsUpClicked: false,
          thumbsDownClicked: false,
          voteResponse: "",
          isTextOnly: data.isTextOnly,
          textData: data.text_data,
          question_recap: questionRecap,
          isEmptyAnswer: false,
          isEmptyChart: false,
        };
      }

      dispatch(setResponses(responseData));
      dispatch(setIsInputDisabled(false));
      dispatch(setIsLoading(false));
      dispatch(setInputText(""));
      const recentChats = await ChatService.getRecentChats();

      dispatch(setRecentChats(recentChats.data.questions));
      dispatch(setIsRecentChatsLoaded(true));
    } catch (error) {
      dispatch(setErrorMessage("An error occurred, please try again"));
      dispatch(setIsInputDisabled(false));
      dispatch(setIsLoading(false));
      dispatch(setInputText(""));

      sendCustomRUMEvent(FAILED_QUESTION_COUNT, {
        [ALIAS]: alias,
        [QUESTION_TEXT]: prompt,
        [SESSION_ID]: session_id,
      });
    }
  };
};

export default ChatHandleSubmit;

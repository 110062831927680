import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Card from "@amzn/meridian/card";
import Text from "@amzn/meridian/text";
import React from "react";
import "./chatCards.scss";
import {
  BREAKDOWN_QUESTIONS,
  INJURY_RATE_QUESTIONS,
  HIGH_LEVEL_ANALYSIS_QUESTIONS,
} from "src/constants/constants";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { setInputText, setShowResult } from "src/store/chatSlice";

const ChatCards = ({ inputRef }) => {
  const dispatch = useAppDispatch();
  const { site } = useAppSelector((state) => state.chatSlice);

  const fillInput = (prompt) => {
    dispatch(setShowResult(false));
    dispatch(setInputText(prompt));
    inputRef.current.focus();
  };

  return (
    <div className="chat-cards">
      <Row widths="fill">
        {BREAKDOWN_QUESTIONS(site).map((question, index) => (
          <div key={index} className="breakdown-cards">
            <Column>
              <Card onClick={() => fillInput(question)}>
                <Text className="chat-card" type="b400">
                  {question}
                </Text>
              </Card>
            </Column>
          </div>
        ))}
      </Row>
      <Row spacingInset="400 none none none" widths="fill">
        {INJURY_RATE_QUESTIONS(site).map((question, index) => (
          <div key={index + 5} className="injury-cards">
            <Column>
              <Card onClick={() => fillInput(question)}>
                <Text className="chat-card" type="b400">
                  {question}
                </Text>
              </Card>
            </Column>
          </div>
        ))}
      </Row>
      <Row spacingInset="400 none none none" widths="fill">
        {HIGH_LEVEL_ANALYSIS_QUESTIONS().map((question, index) => (
          <div key={index + 9} className="high-level-questions-cards">
            <Column>
              <Card onClick={() => fillInput(question)}>
                <Text className="chat-card" type="b400">
                  {question}
                </Text>
              </Card>
            </Column>
          </div>
        ))}
      </Row>
    </div>
  );
};

export default ChatCards;

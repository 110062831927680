import Text from "@amzn/meridian/text";
import React from "react";
import "./chatCards.scss";
// Image by <a href="https://www.freepik.com/icons/chatbot">Icon by Freepik</a>
import chatIcon from "src/images/safer_chat_icon.png";
import PlotlyChart from "src/components/plotlyChart/plotlyChart";
import Link from "@amzn/meridian/link";

const ChatResult = ({ output }) => {
  return (
    <div className="result-data">
      <img className="chat-icon-image" src={chatIcon} alt="Safer Chat Icon" />
      {output.isTextOnly ? (
        <div>
          <div className="result-content answer-text-only">
            <Text type="b300">
              For safety procedure questions, please refer to our new policy
              search tool, SAIGE, which can be accessed via this link:
              <span>&nbsp;</span>
              <Link href="https://man2bzq2.chat.qbusiness.us-east-1.on.aws/#/chat/">
                https://man2bzq2.chat.qbusiness.us-east-1.on.aws/#/chat
              </Link>
            </Text>
          </div>
        </div>
      ) : output.isEmptyAnswer ? (
        output.isEmptyChart ? (
          <div className="result-content">
            <Text type="b300">
              Since the query returned no data, I'm unable to create the chart.
              I store data from the past 24 months. Please consider using a
              different site or adjusting the date range.
            </Text>
          </div>
        ) : (
          <div className="result-content">
            <Text type="b300">
              Sorry, I can't fulfill your request right now. I'm currently
              focused on region, country, org, suborg, site type and site-level
              analysis for breakdowns and injury rates. I store data from the
              last 24 months. <br />
              <br />
              For safety procedure questions, please refer to our new policy
              search tool, SAIGE, which can be accessed via this link:
              <span>&nbsp;</span>
              <Link href="https://man2bzq2.chat.qbusiness.us-east-1.on.aws/#/chat/">
                https://man2bzq2.chat.qbusiness.us-east-1.on.aws/#/chat
              </Link>
              <br />
              <br />
              Please visit the home page to see sample questions I can answer,
              and feel free to try again.
            </Text>
          </div>
        )
      ) : (
        <div>
          <PlotlyChart chartJson={output.chartResult} output={output} />
        </div>
      )}
    </div>
  );
};

export default ChatResult;

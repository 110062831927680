import React, { useMemo } from "react";
import Plot from "react-plotly.js";
import { DEFAULT_PLOTLY_CONFIG } from "src/constants/constants";
import "./plotlyChart.scss";
import Text from "@amzn/meridian/text";
import Vote from "src/components/chat/content/vote";

const PlotlyChart = ({ chartJson, output }) => {
  const chartData = JSON.parse(chartJson);
  const data = useMemo(() => {
    return chartData.data;
  }, [chartJson]);
  const layout = useMemo(() => {
    return chartData.layout;
  }, [chartJson]);

  return (
    <div>
      <p
        className="plotly-question_recap"
        dangerouslySetInnerHTML={{
          __html: output.question_recap,
        }}
      />
      <div className="result-content">
        <Plot
          className="js-plotly-plot"
          data={data}
          layout={layout}
          config={DEFAULT_PLOTLY_CONFIG}
        />
        <Vote output={output} />
      </div>
    </div>
  );
};

export default PlotlyChart;

import React from "react";
import Masthead, { MastheadTitle } from "@amzn/meridian/masthead";
import logo from "src/images/logo.png";
import "./header.scss";

const header = () => {
  return (
    <Masthead>
      <MastheadTitle>
        <div className="heading-container">
          <img className="heading-logo" src={logo} alt="Heading logo" />
          <h1 className="heading">Safer Chatbot</h1>
        </div>
      </MastheadTitle>
    </Masthead>
  );
};
export default header;

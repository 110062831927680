import { Auth, CognitoUser } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { v4 as uuidv4 } from "uuid";
import apiClient from "src/utils/apiClient";
import axios from "axios";

const USER = "user";
const TOKEN = "token";
const ASSETS_LAST_MODIFIED = "assets-last-modified";

const getCurrentUser = async (): Promise<CognitoUser | null> => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (e) {
    // TODO: add error log
    console.error(e);
    return null;
  }
};

const signIn = () =>
  Auth.federatedSignIn({
    customProvider: "FederateOIDC",
    customState: window.location.href,
  });

const login = async () => {
  const stage: string = "prod";
  if (stage !== "test") {
    const lastModified = await getAssetsLastModified();
    if (localStorage.getItem(ASSETS_LAST_MODIFIED) === null) {
      localStorage.setItem(ASSETS_LAST_MODIFIED, lastModified);
    } else if (localStorage.getItem(ASSETS_LAST_MODIFIED) !== lastModified) {
      localStorage.removeItem(ASSETS_LAST_MODIFIED);
      // refreshPage();
    }
  }

  const currentUser = {
    alias: "",
    token: "",
    lastName: "",
    firstName: "",
    email: "",
    ldap: [] as string[],
    loc_descr: "",
  };

  // Listen for the customState event
  Hub.listen("auth", ({ payload: { data, event } }) => {
    switch (event) {
      case "customOAuthState":
        // set current url to federatedSignIn customState
        window.location.href = data;
        break;
    }
  });

  const user = await getCurrentUser();

  if (!user) {
    // TODO: add sign in log
    console.info("No session found, attempting to start a new one...");
    await signIn();
  }

  if (user) {
    user.getSession((_: Error | null, session: CognitoUserSession) => {
      if (session) {
        const token = session.getIdToken().getJwtToken();
        currentUser.token = token;
        const decodeToken = session.getIdToken().decodePayload();
        currentUser.alias =
          decodeToken?.identities?.[0]?.userId ??
          decodeToken["email"]!.split("@")[0];
        currentUser.ldap = decodeToken["custom:LDAP_GROUP"].split(",");
        currentUser.firstName = decodeToken["given_name"];
        currentUser.lastName = decodeToken["family_name"];
        currentUser.email = decodeToken["email"];
        currentUser.loc_descr = decodeToken["custom:AMZN_LOC_DESCR"]
          .split("-")[0]
          .trim();
        localStorage.setItem("token", token);
        apiClient.interceptors.request.use(
          async (config) => {
            config.headers = {
              authorization: "Bearer " + localStorage.getItem(TOKEN),
              "Content-Type": "application/json",
              clientRequestId: uuidv4(),
              // must put variable name in square bracket to evaluate to its value first
              // then the value can be used as key name
              [ASSETS_LAST_MODIFIED]:
                localStorage.getItem(ASSETS_LAST_MODIFIED) || "Not set",
            };
            return config;
          },
          (error) => {
            Promise.reject(error);
          },
        );
      }
    });
  }
  return currentUser;
};

const getAssetsLastModified = async () => {
  const res = await axios.head(`https://prod.na.safer.whs.amazon.dev`);
  return res.headers["last-modified"];
};

const refreshPage = async () => {
  console.log("assets changed, refresh page...");
  window.location.reload();
};

const logout = () => {
  localStorage.removeItem(USER);
  localStorage.removeItem(TOKEN);
};

export default {
  login,
  logout,
};

import React from "react";
import { createRoot } from "react-dom/client";
import { KatSpinner } from "@amzn/katal-react";
import { Provider } from "react-redux";
import App from "./components/App";
import AppInitWrapper from "./components/AppInitWrapper";
import "./logger";

import "@amzn/katal-components/styles.css";
import { Init } from "src/config/auth";
import { BrowserRouter } from "react-router-dom";
import store from "src/store/store";

Init();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AppInitWrapper>
          <React.Suspense fallback={<KatSpinner size="large" />}>
            <App />
          </React.Suspense>
        </AppInitWrapper>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);

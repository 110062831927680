import React, { useEffect } from "react";
import AppLayout from "@amzn/meridian/app-layout";
import ErrorBoundary from "src/components/shared/errorBoundary/errorBoundary";
import { useAppSelector, useAppDispatch } from "src/store/store";
import { getUser } from "src/store/userSlice";
import Header from "src/components/shared/header/header";
import SiteToaster from "src/components/shared/siteToaster/siteToaster";
import MainContent from "src/components/shared/mainContent/mainContent";
import ChatSideMenu from "src/components/shared/sideMenu/sideMenu";
import { setSessionId } from "src/store/chatSlice";
import { v4 as uuidv4 } from "uuid";
import { initAwsRum } from "src/utils/awsRUM";

const App = () => {
  const dispatch = useAppDispatch();
  const { alias, token } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(setSessionId(uuidv4()));

    if (!token) {
      dispatch(getUser());
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (alias) {
      initAwsRum(alias);
    }
  }, [alias]);

  return (
    <ErrorBoundary>
      <AppLayout headerComponent={Header} sidebarComponent={ChatSideMenu}>
        <SiteToaster />
        <ChatSideMenu />
        <MainContent />
      </AppLayout>
    </ErrorBoundary>
  );
};

export default App;
